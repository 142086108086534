import React, { useEffect } from "react"
import { Container, Row, Col, Button } from "react-bootstrap"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { gsap } from "gsap"
import $ from "jquery"

const GalleryOverlay = props => {
  function changeMainBG(evt, i) {
    // $("#image-element").attr("src", evt.target.src)
    $("#instagram-wrapper")
    .children(".gatsby-image-wrapper")
    .children("picture")
    .children("img")
    .attr("src", evt.target.src)
  $("#instagram-wrapper")
    .children(".gatsby-image-wrapper")
    .children("picture")
    .children("img")
    .attr("srcset", evt.target.srcset)
  $("#instagram-wrapper")
    .children(".gatsby-image-wrapper")
    .children("picture")
    .children("source")
    .attr("srcset", evt.target.srcset)
    gsap.to("#samples-wrapper", {
      scrollLeft:
        ((i - 1) *
          ($("#samples-container").width() - $("#samples-wrapper").width())) /
        (props.imagesArray.length - 3),
      duration: 1,
    })
  }

  useEffect(() => {
    // array.forEach(element => {
    // });
  }, [props])

  const imagesElements = () => {
    return props.imagesArray.map((image, ind) => {
      let sampleClass
      if (ind !== 0 && ind !== props.imagesArray.length - 1) {
        sampleClass = "img-line sample"
      } else {
        if (ind === 0) {
          sampleClass = "img-line sample sample-first"
        } else {
          sampleClass = "img-line sample sample-last"
        }
      }
      return (
        <div className={sampleClass} key={ind}>
          <Button
            onClick={e => changeMainBG(e, ind)}
            className="gallery-image-button-instagram"
          >
            <GatsbyImage
              image={getImage(image)}
              backgroundColor={false}
              alt="daucodesigns"
              className="img-responsive img-instagram"
            />
          </Button>
        </div>
      )
    })
  }

  return (
    <>
      <Container className="big-container" style={{ height: "100%" }}>
        <Row style={{ height: "100%" }}>
          <Col style={{ height: "100%" }}>
            <div
              id="instagram-wrapper"
              style={{
                margin: 0,
                height: "calc(100% - 130px)",
                overflow: "visible",
              }}
            >
              <GatsbyImage
                image={getImage(props.imagesArray[props.firstImage])}
                backgroundColor={false}
                alt="daucodesigns"
                className="img-responsive img-instagram"
              />
              <Container className="">
                <Row>
                  <Col sm={1} />
                  <Col id="samples-wrapper" sm={10}>
                    <div
                      id="samples-container"
                      style={{ width: "max-content", margin: "auto" }}
                    >
                      {imagesElements()}
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default GalleryOverlay
