import React from "react"
import GoogleMapComponent from "./GoogleMapComponent"

class GoogleMapWrapper extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      styles: {
        width: "100%",
        height: "368px",
      },
    }
  }

  componentDidMount = () => {
    this.setMapHeight()
    if (typeof window !== "undefined") {
      window.addEventListener("resize", this.setMapHeight)
    }
  }

  setMapHeight = () => {
    if (typeof window !== "undefined") {
      const h =
        (window.innerWidth > 576
          ? window.innerHeight - 80
          : window.innerHeight - 40
        ).toString() + "px"
      this.setState({
        styles: {
          width: "100%",
          height: h,
        },
      })
    }
  }

  render() {
    return (
      <div style={this.state.styles}>
        <GoogleMapComponent
          googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyAoXIXeSPeQpOVZadg8Kr3yFLOCJVtnLTI"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `100%` }} />}
          mapElement={<div style={{ height: `100%` }} />}
          zoom={this.props.zoom}
          position={this.props.position}
          center={this.props.center}
        />
      </div>
    )
  }
}

export default GoogleMapWrapper
