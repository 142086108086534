import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { Icon } from "react-icons-kit"
import { times } from "react-icons-kit/fa/times"
import LocationModal from "./locationModal"


class MapModal extends React.Component {
  render() {
    return (
      <>
        {this.props.show ? (
          <div className="overGris">
            <div
              style={{ zIndex: 3000, textAlign: "center" }}
            >
              <section className="modal-main">
                <Container>
                  <Row>
                    <Col xs={1}>
                      <div style={{ color: "white" }}>
                        <Icon size={"40px"} icon={times} onClick={this.props.handleClose} style={{cursor: "pointer"}}/>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col style={{ padding: "0 0 12px 0" }}>
                      <LocationModal
                        zoom="16"
                        position={this.props.coords}
                        center={this.props.coords}
                      />
                    </Col>
                  </Row>
                </Container>

                {this.props.children}
              </section>
            </div>
          </div>
        ) : (
          <></>
        )}
      </>
    )
  }
}

export default MapModal
