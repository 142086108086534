import React from "react"


import MyMapComponent from './GMaps/GoogleMapWrapperModal'

class LocationModal extends React.Component {

    render() {

        return (
            <MyMapComponent className="map-component" zoom={this.props.zoom} position={this.props.position} center={this.props.center}/>
        )
    }
}

export default LocationModal
