import React, { useState, useEffect } from "react"
import { Container, Row, Col } from "react-bootstrap"
import $ from "jquery"
import { Icon } from "react-icons-kit"
import { times } from "react-icons-kit/fa/times"
import GalleryOverlay from "./gallery-overlay"
import MobGallery from "./mob-gallery-comp"

function InstagramModalGallery(props) {
  const [windowWidth, setWindowWidth] = useState(0)

  const setVideoWindow = () => {
    setWindowWidth($(window).width())
  }

  useEffect(() => {
    setWindowWidth($(window).width())
    if (typeof window !== "undefined") {
      setWindowWidth($(window).width())
      window.addEventListener("resize", setVideoWindow)
    }
  }, [])

  return (
    <>
      {props.show ? (
        <div className="overGris" style={{ backgroundColor: "#444444" }}>
          <div style={{ zIndex: 3000, textAlign: "center", height: "100%" }}>
            <section className="modal-main" style={{ height: "100%" }}>
              <Container style={{ height: "100%" }}>
                <Row style={{ height: "100%" }}>
                  <Col sm={1} style={{ height: "100%" }}>
                    <div
                      style={{
                        color: "white",
                        margin: "20px 0 0 20px",
                        float: "right",
                      }}
                    >
                      <Icon
                        size={"30px"}
                        icon={times}
                        onClick={props.handleClose}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </Col>
                  <Col sm={10} style={{ height: "100%" }}>
                    <Container style={{ height: "100%" }}>
                      <Row style={{ height: "100%" }}>
                        <Col
                          style={{ padding: "12px 0 12px 0", height: "100%" }}
                        >
                          {windowWidth >= 768 ? (
                            <GalleryOverlay
                              imagesArray={props.images}
                              firstImage={props.firstImage}
                            />
                          ) : (
                            <MobGallery
                              imagesArray={props.images}
                              windowWidth={windowWidth}
                            />
                          )}
                        </Col>
                      </Row>
                    </Container>
                  </Col>
                </Row>
              </Container>
            </section>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  )
}

export default InstagramModalGallery
